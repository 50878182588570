<template>
  <div class="container mx-auto px-2 xl:px-0">
    <hr class="border-t-4 border-gray-900" />
    <div class="flex justify-between mt-2">
      <span class="box">Datenschutz</span>
      <span class="box">contact: anez@anez.hu</span>
      <span class="box text-gray-600">Design by petzispace.com</span>
    </div>
    <Social />
  </div>
</template>

<script>
import Social from './Social';

export default {
  components: {
    Social
  }
};
</script>

<style lang="scss" scoped>
ul {
  position: relative;
  display: flex;
  color: black;
  justify-content: center;
  align-items: center;
}
</style>
