<template>
  <div id="intro">
    <div class="container mx-auto relative h-full">
      <img
        class="absolute top-o my-4 ml-4 xl:ml-0"
        src="../assets/img/logo_white.svg"
        alt="ANEZ logo"
      />
      <!-- <span
        class="headline block absolute leading-none text-white bottom-0 ml-4 xl:ml-0 mb-8 sm:w-3/5 text-6xl"
      >ANEZ – Erdő<br/>Out now!</span> -->
      <div
        class="flex flex-row absolute w-full justify-center bottom-0 cursor-pointer"
        v-on:click="scrollDown()"
      >
        <img class="m-2" src="../assets/img/arrow.svg" alt="Scroll down" />
      </div>
      <Social class="mb-8" />
    </div>
  </div>
</template>

<script>
import Social from './Social';

export default {
  methods: {
    scrollDown: function() {
      document.getElementById('video').scrollIntoView({ behavior: 'smooth' });
    }
  },
  components: {
    Social
  }
};
</script>

<style lang="scss" scoped>
div#intro {
  height: 50vh;
  background-image: url('../assets/img/ANEZ_21-01-23_Antal-3.jpg');
  background-size: cover;
  background-position: center center;

  @media screen and (min-width: 1024px) {
    height: 100vh;
  }
}
span.headline {
  font-family: 'Anton', sans-serif;
  text-transform: uppercase;

  @media screen and (min-width: 1024px) {
    font-size: 7rem;
  }
}

ul {
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
}
</style>
